/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import ErrorInfo from '@core_modules/home/pages/default/components/ErrorInfo';
import { Tabs, Tab, AppBar } from '@commons/Tabs';
import Skeleton from '@material-ui/lab/Skeleton';
import { useMemo } from 'react';
import Link from 'next/link';
import CarouselV2 from '@commons/CarouselV2';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import { setResolver, getResolver } from '@helper_localstorage';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { SECONDARY, GRAY_100, PRIMARY } from '@src_theme/colors';
import { useTranslation } from '@i18n';
import classNames from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import Image from 'next/image';
// import Thumbor from '@common_imageThumbor';
import { getThumborUrl } from '@root/src/helpers/thumborConfig';
import { handleTabLabel } from '@root/src/helpers/labelText';
import WidgetHeader from './commons/WidgetHeader';
import ProductCard from './ProductCard';
import ProductCardContainer from './ProductCard/components/Container';
import useHomeStyles from './style';

const useStyles = makeStyles({
    root: {
        marginTop: 24,
        '@media (max-width: 768px)': {
            marginTop: 16,
        },
    },
    rootOfCarousel: {
        position: 'relative',
        display: 'flex',
        height: 348,
        alignItems: 'center',
        '@media (max-width: 768px)': {
            height: 280,
        },
    },
    rootOfGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
        justifyItems: 'center',
        columnGap: '4px',
        rowGap: '8px',
        '@media (min-width: 360px)': {
            gridTemplateColumns: 'repeat(auto-fill, minmax(148px, 1fr))',
        },
        '@media (min-width: 768px)': {
            gridTemplateColumns: 'repeat(auto-fill, minmax(188px, 1fr))',
            columnGap: '16px',
            rowGap: '26px',
        },
    },
    banner: {
        '& > div': {
            width: '100%',
        },
        '& img': {
            borderRadius: 8,
            objectFit: 'cover',
            height: 348,
            width: 240,
            position: 'absolute',
            left: 0,
            top: 0,
            '@media (max-width: 768px)': {
                height: 280,
                width: 'auto',
                position: 'absolute',
                left: 0,
                top: 0,
            },
        },
        display: 'block',
        zIndex: '-1',
        position: 'absolute',
        left: 0,
        top: 0,
        marginBottom: 0,
    },
    gridBanner: {
        display: 'block',
        marginBottom: 10,
        gridColumn: '1 / -1',
        width: '100%',
    },
    carousel: {
        width: '100%',
    },
    productCardSkeletons: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        overflow: 'hidden',
        '& > :first-child': {
            width: '100%',
            paddingBottom: '100%',
        },
        '& > :nth-child(2)': {
            width: '50%',
            marginLeft: 12,
        },
        '& > :nth-child(3)': {
            width: '70%',
            marginLeft: 12,
        },
        '& > :nth-child(4)': {
            width: '70%',
            marginLeft: 12,
            marginTop: 'auto',
            marginBottom: 12,
        },
    },
    leftArrowStyle: {
        left: '-15px',
        paddingLeft: '1.8%',
        top: '50%',
        transform: 'translateY(-20px)',
        height: '42px',
        width: '42px',
    },
    rightArrowStyle: {
        right: '0',
        paddingRight: '1%',
        top: '50%',
        transform: 'translateY(-20px)',
        height: '42px',
        width: '42px',
        '@media (min-width: 1200px)': {
            right: '-5px',
        },
    },
    seeAllCard: {
        display: 'flex',
        textAlign: 'center',
        border: '1px solid',
        borderColor: GRAY_100,
        boxShadow: `0px 1px 1px ${GRAY_100}`,
        fontWeight: 600,
        color: SECONDARY,
        justifyContent: 'center',
        alignItems: 'center',
        '& > svg': { fontSize: '1rem' },
    },
    ghostCard: {
        width: 186,
        display: 'block',
        '@media (max-width: 768px)': {
            width: 160,
        },
    },
    fullRow: {
        gridColumn: '1 / -1',
        width: '100%',
    },
    seeMoreGridButton: {
        '@media (max-width: 767px)': {
            marginTop: 16,
        },
    },
    animationOut: {
        animation: '0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both running $effectOut',
    },
    animationIn: {
        animation: '0.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both running $effectIn',
    },
    '@keyframes effectOut': {
        '0%': {
            opacity: 0,
            transform: 'translateX(-102px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateX(0)',
        },
    },
    '@keyframes effectIn': {
        '0%': {
            opacity: 1,
            transform: 'translateX(0)',
        },
        '100%': {
            opacity: 0,
            transform: 'translateX(-102px)',
        },
    },
});

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const Button = withStyles({
    root: {
        border: '1px solid #4479BA',
        borderRadius: 4,
        paddingTop: 18,
        paddingBottom: 14,
        color: PRIMARY,
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1,
        position: 'relative',
    },
})(ButtonBase);

function SkeletonCard() {
    const styles = useStyles();

    return (
        <ProductCardContainer className={styles.productCardSkeletons}>
            <Skeleton variant="rect" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </ProductCardContainer>
    );
}

function LoadMoreButton({ loading, onClick, buttonProps = {} }) {
    const { t } = useTranslation(['home']);
    const styles = useStyles();

    if (loading) return <CircularProgress size={24} className={styles.fullRow} />;

    return (
        <Button variant="outlined" onClick={onClick} className={classNames(styles.fullRow, buttonProps.className)}>
            {t('loadMoreProducts')}
        </Button>
    );
}

const imgUrl = (src) => (
    `${getThumborUrl()}/unsafe/240x348/filters:format(webp)/${src}`);

function ProductPanel({
    name,
    targetUrl,
    bannerImage,
    products,
    onClickBanner,
    loading,
    loadingMore,
    grid,
    onLoadMore,
    canLoadMore,
    storeConfig,
    // carouselMarginTopInMobile,
}) {
    if (typeof window === 'undefined') return null;

    const [showImage, setShowImage] = React.useState(true);
    const { t } = useTranslation(['home']);
    const styles = useStyles();
    const homeStyles = useHomeStyles();
    const isDesktop = useMediaQuery('(min-width: 768px)');

    const handleHideImage = () => {
        setShowImage(false);
    };

    const handleShowImage = () => {
        setShowImage(true);
    };

    const products2 = useMemo(
        () => products.map((product) => ({
            ...product,
            name: product.name,
            url: product.url_key,
            imageSrc: product.small_image.url,
            price: product.price_range.minimum_price.regular_price.value,
        })),
        [products],
    );

    return (
        <div className={classNames(styles.root, grid ? styles.rootOfGrid : styles.rootOfCarousel)}>
            {bannerImage && !grid && (
                <>
                    <Link prefetch={false} href={targetUrl}>
                        <a
                            onClick={onClickBanner}
                            className={classNames(
                                styles.banner,
                                grid && styles.gridBanner,
                                showImage ? styles.animationOut : styles.animationIn,
                            )}
                        >
                            <picture>
                                <source
                                    srcSet={imgUrl(bannerImage)}
                                    type="image/webp"
                                />
                                <source
                                    srcSet={imgUrl(bannerImage)}
                                    type="image/jpeg"
                                />
                                <img
                                    src={imgUrl(bannerImage)}
                                    alt={name}
                                    loading="lazy"
                                    width={240}
                                    height={348}
                                />
                            </picture>
                        </a>
                    </Link>
                </>
            )}
            {grid ? (
                <>
                    {products2.map((product) => (
                        <ProductCard storeConfig={storeConfig} key={product.id} {...product} />
                    ))}
                    {loading ? (
                        <>
                            <SkeletonCard />
                            <SkeletonCard />
                        </>
                    ) : (
                        canLoadMore && (
                            <LoadMoreButton
                                loading={loadingMore}
                                onClick={onLoadMore}
                                buttonProps={{ className: styles.seeMoreGridButton }}
                            />
                        )
                    )}
                </>
            ) : (
                <>
                    <div className={styles.carousel} style={{ ...(!isDesktop && { marginTop: 0 }) }}>
                        <CarouselV2
                            displayArrows={!loading}
                            handleHideImage={handleHideImage}
                            handleShowImage={handleShowImage}
                        >
                            <div className={styles.ghostCard} />
                            {loading ? (
                                <>
                                    <SkeletonCard />
                                    <SkeletonCard />
                                </>
                            ) : (
                                <>
                                    {products2.map((product) => (
                                        <ProductCard
                                            key={product.id}
                                            className={homeStyles.productCardSlide}
                                            imgProps={{ className: homeStyles.productCardSlideImg }}
                                            {...product}
                                            storeConfig={storeConfig}
                                        />
                                    ))}
                                    <Link prefetch={false} href={targetUrl}>
                                        <a>
                                            <ProductCardContainer className={styles.seeAllCard}>
                                                <span>{t('home:seeAllData')}</span>
                                                <RightArrowIcon />
                                            </ProductCardContainer>
                                        </a>
                                    </Link>
                                </>
                            )}
                        </CarouselV2>
                    </div>
                </>
            )}
        </div>
    );
}

export default function CategoryTabs({
    title,
    titleSuffix,
    categories,
    selectedCategoryIndex,
    onChangeTab,
    loadingCategories,
    loadingProducts,
    error,
    grid = false,
    onLoadMore = () => {},
    canLoadMore = false,
    loadingMore = false,
    carouselMarginTopInMobile = -125,
    storeConfig,
}) {
    const { t } = useTranslation(['home']);

    if (loadingCategories) return <Skeleton variant="rect" height={468} />;

    if (error) return <ErrorInfo variant="error" text={t('home:errorFetchData')} />;

    return (
        <>
            <WidgetHeader title={title}>{titleSuffix}</WidgetHeader>
            <AppBar position="static" color="default">
                <Tabs value={selectedCategoryIndex} onChange={onChangeTab} variant="scrollable" scrollButtons="off">
                    {categories.map(({ id, name }) => (
                        <Tab key={id} label={handleTabLabel(name)} {...a11yProps(id)} />
                    ))}
                </Tabs>
            </AppBar>
            {categories.map((category, index) => (
                <div
                    role="tabpanel"
                    id={`scrollable-auto-tabpanel-${category.id}`}
                    aria-labelledby={`scrollable-auto-tab-${category.id}`}
                    hidden={selectedCategoryIndex !== index}
                >
                    <ProductPanel
                        storeConfig={storeConfig}
                        index={index}
                        name={category.name}
                        products={category.products}
                        targetUrl={`/${category.url_path}`}
                        bannerImage={category.image_banner_home}
                        onClickBanner={() => {
                            const urlResolver = getResolver();
                            urlResolver[`/${category.url_path}`] = {
                                type: 'CATEGORY',
                                id: category.id,
                            };
                            setResolver(urlResolver);
                        }}
                        loading={loadingProducts}
                        grid={grid}
                        onLoadMore={() => onLoadMore(category)}
                        canLoadMore={canLoadMore}
                        loadingMore={loadingMore}
                        carouselMarginTopInMobile={carouselMarginTopInMobile}
                    />
                </div>
            ))}
        </>
    );
}
